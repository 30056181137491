export const groupByToMap = <T, K>(array: T[], callbackfn: (value: T, index: number, array: T[]) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>();
  array.forEach((value, index, array) => {
    const key = callbackfn(value, index, array);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key)?.push(value);
  });
  return map;
};

export const groupByToMapWithTransform = <T, K, V>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => K,
  transform: (value: T) => V,
): Map<K, V[]> => {
  const map = new Map<K, V[]>();
  array.forEach((value, index, array) => {
    const key = callbackfn(value, index, array);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key)?.push(transform(value));
  });
  return map;
};

export const compareStrings = (a: string, b: string): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
