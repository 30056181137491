import _ from 'lodash';
import { DateTime, Duration } from 'luxon';

import { compareStrings } from '../util/js';
import { LabelTexts } from './appConfig';
import { Direction } from './direction';
import { IdeaHistory } from './idea';
import { PositionHistory } from './position';

export interface Company {
  id: string;
  ticker: string | null;
  name: string;
  currency: string | null;
  suspended: boolean | null;
}

export type CompanyLikeObject = {
  companyId: string;
  state: CompanyStateType;
  direction?: Direction | null;
  rank?: number | null;
};

export type ObjectWithOriginalState<T, Nested = 'main'> = T &
  (Nested extends string
    ? {
        originalState: ObjectWithOriginalState<T, null>;
      }
    : unknown);

export enum CompanyStateType {
  NO_OPINION = 'NO_OPINION',
  FOCUS_NEUTRAL = 'FOCUS_NEUTRAL',
  FOCUS_DIRECTIONAL = 'FOCUS_DIRECTIONAL',
  FOCUS_UNASSIGNED = 'FOCUS_UNASSIGNED',
  BROAD_DIRECTIONAL = 'BROAD_DIRECTIONAL',
  BROAD_NEUTRAL = 'BROAD_NEUTRAL',
  IN_PORTFOLIO = 'IN_PORTFOLIO',
  REMOVED = 'REMOVED',
}

export enum CompanyGroupingStateType {
  FOCUS_UNASSIGNED = 'FOCUS_UNASSIGNED',
  FOCUS_NEUTRAL = 'FOCUS_NEUTRAL',
  FOCUS_SHORT = 'FOCUS_SHORT',
  FOCUS_LONG = 'FOCUS_LONG',
  PORTFOLIO_LONG = 'PORTFOLIO_LONG',
  PORTFOLIO_SHORT = 'PORTFOLIO_SHORT',
  OTHER = 'OTHER',
}

interface CompanyStateBase<S extends CompanyStateType> {
  state: S;
  direction?: undefined;
  rank?: undefined;
  total?: undefined;
}

export type CompanyStateNoOpinion = CompanyStateBase<CompanyStateType.NO_OPINION>;
export type CompanyStateFocusNeutral = CompanyStateBase<CompanyStateType.FOCUS_NEUTRAL>;
export interface CompanyStateFocusDirectional
  extends Pick<CompanyStateBase<CompanyStateType.FOCUS_DIRECTIONAL>, 'state'> {
  direction: Direction;
  rank: number | null;
  total?: number;
}
export type CompanyStateFocusUnassigned = CompanyStateBase<CompanyStateType.FOCUS_UNASSIGNED>;
export interface CompanyStateBroadDirectional
  extends Pick<CompanyStateBase<CompanyStateType.BROAD_DIRECTIONAL>, 'state'> {
  direction: Direction;
  rank: null;
  total?: number;
}
export type CompanyStateBroadNeutral = CompanyStateBase<CompanyStateType.BROAD_NEUTRAL>;
export type CompanyStateInPortfolio = CompanyStateBase<CompanyStateType.IN_PORTFOLIO>;
export type CompanyStateRemoved = CompanyStateBase<CompanyStateType.REMOVED>;

export type CompanyState =
  | CompanyStateNoOpinion
  | CompanyStateFocusNeutral
  | CompanyStateFocusDirectional
  | CompanyStateFocusUnassigned
  | CompanyStateBroadDirectional
  | CompanyStateBroadNeutral
  | CompanyStateInPortfolio
  | CompanyStateRemoved;

export interface CompanyStateComplete {
  userId: string;
  companyId: string;
  state: CompanyState;
  rank: {
    direction: Direction;
    current: number;
    total: number;
  } | null;
  lastTransitionId: string;
}

export interface CompanyOverview {
  company: Company;
  currentState: CompanyState;
  lastWeekState: CompanyState | null;
  priceTarget: ValidCompanyPriceTarget | null;
}

export interface BuysideIdeaReturnData {
  companyId: string;
  prevCumulativeReturn: number;
  beginPrice: number;
  sharesTotal: number;
  dividendGain: number;
  costs: number;
  status: 'OK' | 'PRICE_NOT_FILLED' | 'MISSING_TID' | 'MISSING_OPEN_LEG' | 'POSITION_NOT_PROCESSED';
  ideaOpenTime: DateTime;
  ideaOpenPrice?: number;
}

export type BuysideIdeaReturnDataMap = { [companyId: string]: BuysideIdeaReturnData };

export type CompanyOverviewMap = Record<string, CompanyOverview>;

export interface CompanyStateTransition {
  id: string;
  transitionTime: DateTime;
  transitionedBy: string;
  state: CompanyState;
}

export interface CompanyDetails {
  company: Company;
  inCoverage: boolean;
  state: CompanyState | null;
  rank: {
    current: number;
    total: number;
  } | null;
  stateHistory: CompanyStateTransition[];
  ideaHistories: IdeaHistory[];
  positionHistories: PositionHistory[];
  latestPriceTarget: ValidCompanyPriceTarget | null;
  priceTargetHistory: CompanyPriceTarget[];
}

export interface CompanyStateChange {
  state: CompanyState;
  explicit: boolean;
}

export type CompanyStateChangeMap = { [companyId: string]: CompanyStateChange };

export type CompanyAdditionMap = { [company: string]: Company };

export type ColumnSearchEnabledMap = { [column: string]: boolean };

export interface UpdateCompanyStateInput {
  state: CompanyState;
  pendingChanges: CompanyStateChangeMap;
  preview: boolean;
  initialChange?: boolean;
}

export interface CompanyPriceTargetInput {
  baseCase: number;
  bearCase: number;
  bullCase: number;
  duration: Duration;
  realizationProbability: number;
}

// for change-log, we want to be able to display changes to both old and new style price targets
export interface UnifiedCompanyPriceTargetFields extends CompanyPriceTargetInput {
  targetDate: DateTime | null;
}

interface BaseCompanyPriceTarget {
  id: string;
  time: DateTime;
  createdBy: string;
  bearTargetDate: DateTime | null;
  bullTargetDate: DateTime | null;
  bearRealizationProbability: number | null;
  bullRealizationProbability: number | null;
  metric: string | null;
  bearMetric: string | null;
  bullMetric: string | null;
  metricYear: string | null;
  bearMetricYear: string | null;
  bullMetricYear: string | null;
  metricValue: number | null;
  bearMetricValue: number | null;
  bullMetricValue: number | null;
  multiple: number | null;
  bearMultiple: number | null;
  bullMultiple: number | null;
}

export interface ValidCompanyPriceTarget extends BaseCompanyPriceTarget {
  valid: true;
  baseCase: number;
  bearCase: number;
  bullCase: number;
  duration: Duration | null;
  targetDate: DateTime | null;
  realizationProbability: number;
}

export interface InvalidCompanyPriceTarget extends BaseCompanyPriceTarget {
  valid: false;
  baseCase: null;
  bearCase: null;
  bullCase: null;
  duration: null;
  targetDate: null;
  realizationProbability: null;
}

export type CompanyPriceTarget = ValidCompanyPriceTarget | InvalidCompanyPriceTarget;

export function getInferredTargetDateFromPriceTarget(priceTarget: ValidCompanyPriceTarget): DateTime | null {
  if (priceTarget.targetDate != null) {
    return priceTarget.targetDate;
  } else if (priceTarget.duration != null) {
    return priceTarget.time.plus(priceTarget.duration);
  } else {
    return null;
  }
}

/**
 * Determine if a company state is ranked.
 * @param state The company state
 * @return A boolean indicating whether the given company state is ranked
 */
export function isRanked(state: CompanyState): state is CompanyStateFocusDirectional & { rank: number } {
  return state.state === CompanyStateType.FOCUS_DIRECTIONAL && state.rank !== null;
}

/**
 * Determine if a company state is on a ranked bench.
 * @param state The company state
 * @return A boolean indicating whether the given company state is a ranked bench state
 */
export function isRankedBench(state: CompanyState): state is CompanyStateFocusDirectional & { rank: null } {
  return state.state === CompanyStateType.FOCUS_DIRECTIONAL && state.rank === null;
}

/**
 * Determine if a company state is directional.
 * @param state The company state
 * @return A boolean indicating whether the given company state is ranked
 */
export function isDirectional(state: CompanyState): state is CompanyStateFocusDirectional & { rank: number } {
  return [CompanyStateType.FOCUS_DIRECTIONAL, CompanyStateType.BROAD_DIRECTIONAL].includes(state.state);
}

/**
 * Get the rank from a company state.
 * @param state The company state
 * @return The rank number or undefined if the state does not have a rank
 */
export function getRank(state: CompanyState): number | undefined {
  if (isRanked(state)) {
    return state.rank;
  }
  return undefined;
}

/**
 * Get the status text from a company state for a search dropdown.
 * @param currentState The company state the company already is in
 * @param nextState The company state to move the company to
 * @param labelTexts The label texts from appConfig
 * @return The right label to use for the state
 */
export function getStatusText(
  currentState: CompanyState | undefined,
  nextState: CompanyState,
  labelTexts: LabelTexts,
  suspended: boolean,
): string {
  function status(sameState: boolean, labelText: string) {
    if (sameState) {
      return ` (Already in ${labelText})`;
    } else {
      return ` (Move from ${labelText})`;
    }
  }

  if (suspended && isRanked(nextState) && (!currentState || !isRanked(currentState))) {
    return ' (Suspended stocks cannot be added to Ranked categories)';
  }

  if (!currentState) {
    return '';
  }

  switch (currentState.state) {
    case CompanyStateType.NO_OPINION:
      return status(nextState.state === CompanyStateType.NO_OPINION, labelTexts.NoOpinion);
    case CompanyStateType.FOCUS_NEUTRAL:
      return status(nextState.state === CompanyStateType.FOCUS_NEUTRAL, labelTexts.RankedNeutral);
    case CompanyStateType.FOCUS_UNASSIGNED:
      return status(nextState.state === CompanyStateType.FOCUS_UNASSIGNED, labelTexts.FocusUnassigned);
    case CompanyStateType.FOCUS_DIRECTIONAL: {
      const isCurrentStateRanked = isRanked(currentState);
      const isNextStateSame =
        nextState.state === CompanyStateType.FOCUS_DIRECTIONAL &&
        nextState.direction === currentState.direction &&
        ((currentState.rank === null && nextState.rank === null) ||
          (currentState.rank !== null && nextState.rank !== null));

      if (suspended && isCurrentStateRanked && !isNextStateSame) {
        return ' (Suspended Ranked stocks cannot be moved from their current category)';
      } else if (currentState.direction === Direction.LONG) {
        return status(isNextStateSame, isCurrentStateRanked ? labelTexts.RankedLongPlural : labelTexts.BenchLongPlural);
      }
      return status(isNextStateSame, isCurrentStateRanked ? labelTexts.RankedShortPlural : labelTexts.BenchShortPlural);
    }
    case CompanyStateType.BROAD_DIRECTIONAL: {
      const isNextStateSame =
        nextState.state === CompanyStateType.BROAD_DIRECTIONAL && nextState.direction === currentState.direction;
      if (currentState.direction === Direction.LONG) {
        return status(isNextStateSame, labelTexts.BenchLongPlural);
      }
      return status(isNextStateSame, labelTexts.BenchShortPlural);
    }
    case CompanyStateType.BROAD_NEUTRAL:
      return status(nextState.state === CompanyStateType.BROAD_NEUTRAL, labelTexts.BroadNeutral);
    case CompanyStateType.IN_PORTFOLIO:
      return status(nextState.state === CompanyStateType.IN_PORTFOLIO, labelTexts.Portfolio);
  }
  return '';
}

// Check if two states are from the same state type
export function isSameCompanyStateType(state: CompanyState | undefined, other: CompanyState | undefined) {
  return _.isEqual(
    { ...state, rank: state?.state === CompanyStateType.FOCUS_DIRECTIONAL && state?.rank != null ? 1 : null },
    { ...other, rank: other?.state === CompanyStateType.FOCUS_DIRECTIONAL && other?.rank != null ? 1 : null },
  );
}

// Check if option needs to be disabled
export function shouldDisableCompanyStateChange(
  currentState: CompanyState | undefined,
  nextState: CompanyState,
  suspended: boolean | null,
) {
  if (currentState && isRanked(currentState)) {
    const isNextStateSame =
      nextState.state === CompanyStateType.FOCUS_DIRECTIONAL &&
      nextState.direction === currentState.direction &&
      ((currentState.rank === null && nextState.rank === null) ||
        (currentState.rank !== null && nextState.rank !== null));

    if (suspended && !isNextStateSame) {
      return true;
    }
  } else if (suspended && isRanked(nextState)) {
    return true;
  }

  return isSameCompanyStateType(currentState, nextState);
}

export const compareCompanies = (a: Company, b: Company): number => {
  if (a.ticker !== null && b.ticker !== null) {
    return compareStrings(a.ticker, b.ticker);
  }
  if (a.ticker !== null) {
    return -1;
  }
  if (b.ticker !== null) {
    return 1;
  }
  return compareStrings(a.id, b.id);
};

export interface CompanyEnfusionPriceDetails {
  priceTime: DateTime;
  price: number;
  currency: string;
  companyRollingWeight: number;
  companyId: string;
}

export interface CompanyBorrowCost {
  rateDate: DateTime;
  annualRate: number;
  perShareDailyCost: number;
  borrowRateFound: boolean;
}
