import { CompanyGroupingStateType } from '../../../model/company';
import { PriceTargetCalculations, RawPriceTargetCalculations } from '../../../model/priceTargetCalculations';
import { parseDateTime, parseDuration } from '../../parse';

export const getCompanyStateTypeGroup = (
  companyStateType: string,
  direction?: string | null,
): CompanyGroupingStateType => {
  if (companyStateType === 'IN_PORTFOLIO') {
    return direction === 'LONG' ? CompanyGroupingStateType.PORTFOLIO_LONG : CompanyGroupingStateType.PORTFOLIO_SHORT;
  }

  if (companyStateType === 'FOCUS_NEUTRAL') {
    return CompanyGroupingStateType.FOCUS_NEUTRAL;
  }

  if (companyStateType === 'FOCUS_DIRECTIONAL') {
    return direction === 'LONG' ? CompanyGroupingStateType.FOCUS_LONG : CompanyGroupingStateType.FOCUS_SHORT;
  }

  if (companyStateType === 'FOCUS_UNASSIGNED') {
    return CompanyGroupingStateType.FOCUS_UNASSIGNED;
  }

  return CompanyGroupingStateType.OTHER;
};

export function fromServerMap(rawData: RawPriceTargetCalculations[]): PriceTargetCalculations[] {
  return rawData.map(
    ({
      company,
      companyStateType,
      direction,
      exposure,
      rank,
      price,
      priceTime,
      skew,
      upsideOverDownside,
      daysOld,
      companyPriceTarget,
      baseCaseCalculations,
      bearCaseCalculations,
      bullCaseCalculations,
    }) => ({
      companyId: company.id,
      company: {
        id: company.id,
        ticker: company.ticker,
        name: company.name,
        currency: company.currency,
        suspended: company.suspended,
      },
      state: companyStateType,
      companyStateType,
      companyGroupingStateType: getCompanyStateTypeGroup(companyStateType, direction),
      direction,
      exposure,
      rank,
      price,
      priceTime: parseDateTime(priceTime),
      skew,
      upsideOverDownside,
      daysOld,
      companyPriceTarget: {
        id: companyPriceTarget?.id || null,
        time: companyPriceTarget ? parseDateTime(companyPriceTarget.time) : null,
        createdBy: companyPriceTarget?.createdBy || null,
        valid: companyPriceTarget?.valid || null,
        duration: companyPriceTarget?.duration ? parseDuration(companyPriceTarget?.duration) : null,
      },
      baseCaseCalculations: {
        priceTargetReturn: baseCaseCalculations?.priceTargetReturn || null,
        irr: baseCaseCalculations?.irr || null,
        yearRollingPT: baseCaseCalculations?.yearRollingPT || null,
        yearRollingReturn: baseCaseCalculations?.yearRollingReturn || null,
        caseValue: companyPriceTarget?.baseCase || null,
        targetDate: companyPriceTarget?.targetDate ? parseDateTime(companyPriceTarget?.targetDate) : null,
        realizationProbability: companyPriceTarget?.realizationProbability || null,
        metric: companyPriceTarget?.metric || null,
        metricYear: companyPriceTarget?.metricYear || null,
        metricValue: companyPriceTarget?.metricValue || null,
        multiple: companyPriceTarget?.multiple || null,
      },
      bearCaseCalculations: {
        priceTargetReturn: bearCaseCalculations?.priceTargetReturn || null,
        irr: bearCaseCalculations?.irr || null,
        yearRollingPT: bearCaseCalculations?.yearRollingPT || null,
        yearRollingReturn: bearCaseCalculations?.yearRollingReturn || null,
        caseValue: companyPriceTarget?.bearCase || null,
        targetDate: companyPriceTarget?.bearTargetDate ? parseDateTime(companyPriceTarget?.bearTargetDate) : null,
        realizationProbability: companyPriceTarget?.bearRealizationProbability || null,
        metric: companyPriceTarget?.bearMetric || null,
        metricYear: companyPriceTarget?.bearMetricYear || null,
        metricValue: companyPriceTarget?.bearMetricValue || null,
        multiple: companyPriceTarget?.bearMultiple || null,
      },
      bullCaseCalculations: {
        priceTargetReturn: bullCaseCalculations?.priceTargetReturn || null,
        irr: bullCaseCalculations?.irr || null,
        yearRollingPT: bullCaseCalculations?.yearRollingPT || null,
        yearRollingReturn: bullCaseCalculations?.yearRollingReturn || null,
        caseValue: companyPriceTarget?.bullCase || null,
        targetDate: companyPriceTarget?.bullTargetDate ? parseDateTime(companyPriceTarget?.bullTargetDate) : null,
        realizationProbability: companyPriceTarget?.bullRealizationProbability || null,
        metric: companyPriceTarget?.bullMetric || null,
        metricYear: companyPriceTarget?.bullMetricYear || null,
        metricValue: companyPriceTarget?.bullMetricValue || null,
        multiple: companyPriceTarget?.bullMultiple || null,
      },
    }),
  );
}
